// アプリへの通知の履歴

<template lang="pug">
el-card

  //- 戻るリンク
  div
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  h2.mt-4.font-bold.text-md.text-teal-700 アプリ通知履歴
  p.mt-1.text-sm スマートフォン向けアプリへの通知履歴です。

  //- 一覧
  .mt-5.pb-10
    ul(v-if='notifications')
      li.mt-6.pt-6.px-2.border-t.border-gray-300(v-for='item in notifications')
        .flex.items-center
          .px-2.py-1.text-white.rounded-full.text-xs(
            :class='getTagBgClass(item)'
          ) {{ item.pushNotificationTypeDescription }}
          h3.ml-2.text-lg.flex-1.text-gray-600 {{ item.title }}
        p.mt-1.text-teal-600 {{ item.sentAt }}
        p.mt-1.p-2.whitespace-pre-wrap.bg-yellow-50 {{ item.body }}
    p.text-gray-500(v-else) 通知履歴がありません。
</template>

<script>
import pushNotificationService from '@/services/push-notification'

export default {
  name: 'AdminAppNotifications',

  data() {
    return {
      notifications: undefined,
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      try {
        const res = await pushNotificationService.getListForAdmin()
        if (!res.ok) return
        this.notifications = res.payload.items
      } catch (error) {
        console.error(error)
        return
      }
    },
    getTagBgClass(item) {
      const type = item.pushNotificationTypeCode
      if (type === 'info') return 'bg-blue-500'
      else if (type === 'update_org') return 'bg-yellow-500'
      else return 'bg-gray-500'
    },
  },
}
</script>
